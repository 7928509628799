.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 0.2;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 36px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 650px) {
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 10px;
  }
}